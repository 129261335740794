.projects {
	width: 100%;
	margin-bottom: var(--s-margin);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--md-gap);
}

.projects-title {
	width: fit-content;
	padding: 0 var(--s-padding);
	background-color: var(--dark-accent);

	color: var(--light-accent);
	font-weight: bold;
	font-size: var(--xl-text);

	align-self: flex-start;

	opacity: 0;
}
