.project {
	width: 100%;
	margin-bottom: var(--s-margin);

	display: flex;
	flex-direction: column;
	gap: var(--s-gap);
}

.project-card {
	position: relative;
	width: 100%;
	height: 500px;

	border: var(--simple-border);
	border-radius: var(--card-radius);
	box-shadow: var(--simple-shadow);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 1em;

	overflow: hidden;
	cursor: pointer;

	opacity: 0;
}

.project-thumbnail {
	position: absolute;
	width: 100%;

	object-fit: cover;
	aspect-ratio: 16/9;

	filter: brightness(80%);
	transition: 0.5s ease-in-out;
}

.project-card:hover .project-thumbnail {
	filter: brightness(20%);
	transform: scale(1.05);
}

.project-title {
	font-size: var(--lg-text);
	font-weight: bold;
}

.project-description {
	font-size: var(--md-text);
	text-align: justify;
}

.project-title,
.project-description {
	padding: 0 10%;

	transition: 0.5s ease-in-out;
	transform: translateY(-100%);
	opacity: 0;
	z-index: 2;
}

.project-card:hover .project-title,
.project-card:hover .project-description {
	transform: translateY(0);
	opacity: 1;
}

.info {
	width: 100%;

	display: flex;
	flex-direction: column;
	gap: var(--s-gap);

	opacity: 0;
}

.info-title {
	font-size: var(--s-text);
	letter-spacing: var(--text-spacing);
	opacity: 0.5;
}

.stack-list {
	display: flex;
	flex-wrap: wrap;
	gap: var(--md-gap);
}

.info-stack {
	padding: 0 var(--s-padding);
	background-color: var(--dark-accent);

	color: var(--light-accent);
	font-size: var(--md-text);
	font-weight: bold;
}

.info-links {
	display: flex;
	justify-content: left;
	align-items: center;
	gap: var(--md-gap);
}

.info-link {
	font-size: var(--md-text);
	text-decoration: none;
	color: var(--light-accent);

	display: flex;
	justify-content: center;
	align-items: center;
	gap: var(--s-gap);
}

.info-link:hover {
	cursor: pointer;
	color: var(--link);
}

.info-paragraph {
	font-size: var(--md-text);
	color: var(--light);
}

/** MOBILE STYLES */
@media (max-width: 768px) {
	.project {
		margin-bottom: var(--xs-margin);
	}

	.project-card {
		height: 400px;
	}

	.project-thumbnail {
		height: 100%;
		aspect-ratio: 9/16;
	}

	.project-title {
		font-size: var(--md-text);
	}

	.project-card:hover .project-title {
		top: 30%;
	}

	.project-description {
		font-size: var(--s-text);
	}

	.project-card:hover .project-description {
		top: 50%;
	}

	.info {
		gap: var(--md-gap);
	}

	.info-title {
		font-size: var(--s-text);
	}

	.info-paragraph {
		font-size: var(--s-text);
	}
}
