:root {
	/**COLORS*/
	--dark: rgb(33, 39, 48);
	--dark-accent: rgb(57, 62, 70);
	--light: rgb(237, 237, 237);
	--light-accent: rgb(255, 211, 107);
	--link: rgb(64, 120, 192);

	/**TEXT*/
	--base-font-size: 16px;
	--main-font: system-ui, Optima, Candara, 'Noto Sans', source-sans-pro, sans-serif;
	--xl-text: 3rem;
	--lg-text: 2rem;
	--md-text: 1.5rem;
	--s-text: 1.25rem;
	--text-spacing: 2px;
	--text-height: 1.25;

	/**WIDTH & HEIGHTS*/
	--container-width: 1200px;

	/**PADDINGS, MARGINS & GAPS*/
	--s-padding: 0.5em;
	--lg-padding: 3em;

	--lg-margin: 10em;
	--md-margin: 5em;
	--s-margin: 3em;
	--xs-margin: 1em;

	--lg-gap: 3em;
	--md-gap: 1em;
	--s-gap: 0.5em;

	/**UTILITY*/
	--card-radius: 5px;
	--simple-border: solid 3px var(--dark-accent);
	--simple-shadow: 5px 10px 30px 0px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1280px) {
	:root {
		/**TEXT*/
		--xl-text: 2.5rem;
		--lg-text: 1.75rem;
		--md-text: 1.25rem;
		--s-text: 1rem;

		/**WIDTH & HEIGHTS*/
		--container-width: 95%;

		/**PADDINGS & MARGINS*/
		--lg-margin: 8em;
		--md-margin: 3em;
		--s-margin: 2em;
		--xs-margin: 1em;
	}
}

@media (max-width: 768px) {
	:root {
		/**TEXT*/
		--xl-text: 2.25rem;
		--lg-text: 1.5rem;
		--md-text: 1.15rem;
		--s-text: 0.95rem;
		--text-height: 1.25;

		/**WIDTH & HEIGHTS*/
		--container-width: 92.5%;

		/**PADDINGS & MARGINS*/
		--lg-margin: 8em;
		--md-margin: 3em;
		--s-margin: 2em;
		--xs-margin: 1em;

		--md-gap: 0.75em;
		--s-gap: 0.25em;

		/**UTILITY*/
		--card-radius: 5px;
		--simple-border: solid 3px var(--dark-accent);
		--simple-shadow: 5px 10px 30px 0px rgba(0, 0, 0, 0.5);
	}
}
