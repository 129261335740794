.notfound {
	display: flex;
	justify-content: center;
	align-items: center;
}

.notfound-title {
	padding: 0 var(--s-padding);
	background-color: var(--dark-accent);

	color: var(--light-accent);
	font-weight: bold;
	font-size: var(--xl-text);

	cursor: pointer;
}

.notfound-title:hover {
	opacity: 0.75;
}
