.footer {
	padding-bottom: var(--s-padding);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--s-gap);

	opacity: 0;
}

.footer__text {
	font-size: var(--xs-text);
	letter-spacing: var(--text-spacing);
}
