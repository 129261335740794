.header {
	width: 100%;
	position: fixed;
	top: 0;

	background-color: var(--dark);

	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 2;
}

.nav {
	width: var(--container-width);
	padding: var(--s-padding) 0;

	display: flex;
	justify-content: space-between;
	align-items: center;

	opacity: 0;
}

.nav-brand,
.nav-link {
	color: var(--light-accent);
	font-size: var(--md-text);
	cursor: pointer;
}

.nav-brand:hover,
.nav-link:hover {
	color: var(--link);
}

.nav-list {
	width: 50%;

	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: var(--s-gap);
}

.mobile-nav {
	display: none;
}

/**MOBILE NAV*/

@media (max-width: 768px) {
	.nav {
		display: none;
	}

	.mobile-nav {
		width: var(--container-width);
		padding: var(--s-padding) 0;

		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.nav-brand {
		opacity: 0;
	}

	.mobile-nav > .nav-mobile-toggle {
		opacity: 0;
	}

	.nav-brand,
	.nav-link,
	.nav-mobile-toggle {
		color: var(--light-accent);
		font-size: var(--lg-text);
	}

	.nav-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-color: rgba(0, 0, 0, 0.75);

		display: flex;
		justify-content: center;

		opacity: 0;
		z-index: -1;
		transition: 0.25s ease-in-out;
		pointer-events: none;
	}

	.nav-overlay.open {
		opacity: 1;
		z-index: 2;
		touch-action: none;
		pointer-events: fill;
	}

	.nav-list {
		width: var(--container-width);
		height: 100%;
		padding: var(--lg-padding) 0;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;

		gap: var(--md-gap);
	}

	.nav-overlay .nav-mobile-toggle {
		margin-top: auto;

		opacity: 0;

		transform: translateX(100px);
		transition: 0.25s ease-in-out;
	}

	.nav-overlay .nav-link {
		opacity: 0;

		transform: translateX(100px);
		transition: 0.25s ease-in-out;
	}

	.nav-overlay.open .nav-mobile-toggle,
	.nav-overlay.open .nav-link {
		opacity: 1;
		transform: translateX(0);
	}
}
