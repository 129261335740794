.contact {
	width: 100%;
	margin-bottom: var(--md-margin);

	display: flex;
	flex-direction: column;
	gap: var(--md-gap);
}

.contact-title {
	width: fit-content;
	padding: 0 var(--s-padding);
	background-color: var(--dark-accent);

	color: var(--light-accent);
	font-weight: bold;
	font-size: var(--xl-text);

	opacity: 0;
}

.contact-info {
	display: flex;
	flex-direction: column;
	gap: var(--md-gap);

	opacity: 0;
}

.contact-text {
	display: flex;
	flex-direction: column;
	gap: var(--s-gap);

	font-size: var(--md-text);
	line-height: var(--text-height);
}

.contact-links {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: var(--lg-gap);
}

.social-link {
	font-size: var(--xl-text);
	color: var(--light-accent);
}

.social-link:hover {
	color: var(--link);
}

@media (max-width: 768px) {
	.contact-text {
		font-size: var(--s-text);
		text-align: justify;
	}

	.contact-links {
		justify-content: center;
	}
}
