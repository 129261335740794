.intro {
	width: 100%;
	min-height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--md-gap);
}

.intro-top {
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
	gap: var(--lg-gap);
}

.intro-text {
	width: 60%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: var(--md-gap);

	font-size: var(--lg-text);
	line-height: var(--text-height);
	text-align: start;

	opacity: 0;
}

.intro-title {
	width: fit-content;
	padding: 0 var(--s-padding);
	background-color: var(--dark-accent);

	color: var(--light-accent);
	font-weight: bold;
	font-size: var(--xl-text);
}

.intro-text a {
	text-decoration: none;
	color: var(--light-accent);
}

.intro-text a:hover {
	color: var(--link);
}

.intro-image {
	width: 40%;
	height: 600px;

	object-fit: cover;
	-o-object-fit: cover;
	aspect-ratio: 16/9;

	border-radius: var(--card-radius);
	border: var(--simple-border);
	box-shadow: var(--simple-shadow);

	opacity: 0;
}

.intro-arrow {
	font-size: 5rem;
	stroke: var(--light-accent);

	opacity: 0;
}

/** TABLET STYLES */
@media (min-width: 769px) and (max-width: 1280px) {
	.intro-image {
		width: 350px;
	}
}

/** MOBILE STYLES */
@media (max-width: 768px) {
	.intro {
		width: 100%;
		min-height: 100vh;

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: var(--md-gap);
	}

	.intro-top {
		height: 100%;

		flex-direction: column-reverse;
		justify-content: space-between;
	}

	.intro-image {
		width: 100%;
		height: 200px;

		object-position: 0px 20%;
	}

	.intro-text {
		width: 100%;

		align-items: center;
		gap: var(--md-gap);
		font-size: var(--md-text);

		line-height: var(--text-height);
		text-align: justify;
	}
}
