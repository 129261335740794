.page.grace {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	gap: var(--lg-gap);
	padding: var(--lg-padding);
}

.grace-skeleton {
	border: var(--simple-border);
	box-shadow: var(--simple-shadow);
	border-radius: var(--card-radius);

	cursor: pointer;
}

.grace-skeleton:hover {
	opacity: 0.85;
}

.grace-description {
	font-size: var(--md-text);
	color: var(--light);

	text-align: center;
}

.grace-description a {
	font-weight: bold;
	color: var(--light-accent);
	text-decoration: none;
}

.grace-description a:hover {
	color: var(--link);
}

.grace-back {
	padding: 0 var(--s-padding);
	background-color: var(--dark-accent);

	color: var(--light-accent);
	font-weight: bold;
	font-size: var(--xl-text);
	text-align: center;

	cursor: pointer;
}

.grace-back:hover {
	opacity: 0.75;
}
