html {
	box-sizing: border-box;
	scroll-behavior: smooth;

	font-size: var(--base-font-size);
	color: var(--light);
}

body {
	width: 100%;
	min-height: 100vh;
	background-color: var(--dark);

	display: flex;
	justify-content: center;
	align-items: center;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0;
	padding: 0;
	font-weight: normal;
	font-family: var(--main-font);
}

ol,
ul {
	list-style: none;
}

img {
	max-width: 100%;
	height: auto;
}

@media (max-width: 1199px) {
	html {
		scroll-padding: 70px;
	}
}
