.designs {
	padding: var(--lg-padding) 0;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: var(--md-gap);
}

.designs-info {
	width: var(--container-width);

	display: flex;
	justify-content: space-between;

	opacity: 0;
}

.designs-info-left {
	height: 100%;

	display: flex;
	flex-direction: column;
	gap: var(--s-gap);
}

.designs-info-right {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
}

.designs-back {
	color: var(--light-accent);
	font-size: var(--md-text);
	cursor: pointer;
}

.designs-back:hover {
	color: var(--link);
}

.designs-title {
	width: fit-content;
	padding: 0 var(--s-padding);
	background-color: var(--dark-accent);

	color: var(--light-accent);
	font-weight: bold;
	font-size: var(--lg-text);
}

.designs-description {
	font-size: var(--s-text);
	letter-spacing: var(--text-spacing);
	opacity: 0.5;
}

.designs-list {
	width: var(--container-width);
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: var(--md-gap);
}

.design {
	width: calc(400px - var(--md-gap));
	height: 300px;

	background-size: cover;
	background-position: top left;
	background-repeat: no-repeat;

	border: var(--simple-border);
	border-radius: var(--card-radius);
	box-shadow: var(--simple-shadow);

	overflow: hidden;
	transition: all 0.25s ease-in-out;

	opacity: 0;
}

.design a {
	text-decoration: none;
}

.design-info {
	width: 100%;
	height: 100%;

	padding: 0 var(--s-padding);
	background: rgba(0, 0, 0, 0.75);

	display: flex;
	justify-content: center;
	align-items: center;

	text-align: center;
	transition: all 0.25s ease-in-out;
}

.design-info-title {
	font-size: var(--md-text);
	color: var(--light-accent);

	text-decoration: none;
}

.design:hover {
	background-position: center;
}

.design:hover .design-info {
	opacity: 0;
}

@media (max-width: 1280px) {
	.designs-list {
		gap: var(--md-gap);
	}

	.design {
		width: calc(33% - var(--md-gap));
		height: 200px;
	}

	.designs-info-right {
		width: 50%;
	}
}

@media (max-width: 768px) {
	.designs-list {
		gap: var(--s-gap);
	}

	.design {
		width: calc(50% - var(--s-gap));
		height: 180px;
	}
}
