.tools {
	width: 100%;
	margin-bottom: var(--md-margin);

	display: flex;
	flex-direction: column;
	gap: var(--md-gap);
}

.tools-title {
	width: fit-content;
	padding: 0 var(--s-padding);
	background-color: var(--dark-accent);

	color: var(--light-accent);
	font-weight: bold;
	font-size: var(--xl-text);

	opacity: 0;
}

.tools-description {
	font-size: var(--md-text);
	letter-spacing: var(--text-spacing);

	opacity: 0;
}

.tools-container {
	display: flex;
	justify-content: space-between;

	opacity: 0;
}

.tools-list {
	display: flex;
	flex-direction: column;
	gap: var(--s-gap);
}

.tools-list h3 {
	font-size: var(--lg-text);
	color: var(--light-accent);
}

.tools-list p {
	font-size: var(--md-text);
}

@media (max-width: 768px) {
	.tools-description {
		font-size: var(--s-text);
		text-align: justify;
	}
}
