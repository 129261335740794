.bio {
	width: 100%;
	margin-bottom: var(--md-margin);

	display: flex;
	flex-direction: column;
	gap: var(--md-gap);
}

.bio-title {
	width: fit-content;
	padding: 0 var(--s-padding);
	background-color: var(--dark-accent);

	color: var(--light-accent);
	font-weight: bold;
	font-size: var(--xl-text);

	opacity: 0;
}

.bio-row {
	display: flex;
	align-items: center;
	gap: var(--lg-gap);

	font-size: var(--md-text);
	text-align: justify;

	opacity: 0;
}

.bio-year {
	color: var(--light-accent);
	font-size: var(--lg-text);
}

@media (max-width: 768px) {
	.bio-row {
		font-size: var(--s-text);
	}
}
